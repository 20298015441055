<template>
  <v-dialog v-model="isOpenDialog"
            :fullscreen="$vuetify.breakpoint.xs"
            :transition="$vuetify.breakpoint.xs ? 'dialog-bottom-transition' : 'dialog-transition'"
            max-width="1000"
            persistent
            scrollable>
    <v-card :height="$vuetify.breakpoint.xs ? undefined : '90vh'">
      <v-card-title class="my__v-card-title primary white--text">
        <div>
          <div>
            รายละเอียดเพิ่มเติม
          </div>
          <div class="text-caption"
               style="margin-top: -6px;">
            {{ bridgeName }}
          </div>
        </div>
        <v-icon color="white" @click="close">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-3">
        <InventoryDetailTab
          isDialog
          style="margin-top: -12px;"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InventoryDetailTab from '../Inventory/Detail/InventoryDetailTab'

export default {
  components: { InventoryDetailTab },
  props: {
    value: Boolean
  },
  data: () => ({
    isOpenDialog: false
  }),
  watch: {
    value (newVal, oldVal) {
      this.isOpenDialog = newVal
    }
  },
  computed: {
    bridgeName () {
      return this.$store.state.appbarText.header
    }
  },
  methods: {
    close () {
      this.isOpenDialog = false
      this.$emit('updateInventoryDetailDialog', false)
    }
  }
}
</script>

<style></style>
