<template>
  <div class="pa-3" style="height: 100%;">
    <div class="py-2 d-flex justify-space-between align-center">
      <div class="font-weight-bold">
        เลือกชิ้นส่วนสะพาน
      </div>
      <div class="d-flex justify-end">
        <v-btn v-if="can_add_inspection"
               class="green white--text d-none d-sm-flex"
               @click="$store.dispatch('inspectionDamageDialog/openNewInspection')">
          <v-icon left>mdi-plus</v-icon>
          เพิ่มการตรวจสอบ
        </v-btn>
      </div>
    </div>
    <v-row v-if="$vuetify.breakpoint.xs">
        <v-col sm="6">
          <v-select
            v-model="bridgeSelected"
            :items="bridgeList"
            item-text="name"
            item-value="partcode"
            outlined
            dense
            hide-details
            @change="changeBridge"
          ></v-select>
        </v-col>
        <v-col sm="6">
          <v-select
            v-model="bridgeViewSelectedForm"
            :items="bridgeView"
            item-text="name_th"
            item-value="name_eng"
            outlined
            dense
            hide-details
            @change="changeView"
          ></v-select>
        </v-col>
      </v-row>
    <v-row v-else align="center"
           class="ma-0">
      <v-col class="pa-0 pr-sm-3 pb-3 pb-sm-0" cols="12" sm="auto">
        <v-btn-toggle :dense="$vuetify.breakpoint.xs"
                      color="primary"
                      mandatory>
          <v-btn v-for="bridge in bridgeList"
                 :key="bridge.partcode"
                 @click="changeBridge(bridge.partcode)">
            {{ bridge.name }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col class="pa-0" cols="auto">
        <v-btn-toggle :dense="$vuetify.breakpoint.xs"
                      color="primary"
                      mandatory>
          <v-btn v-for="view in bridgeView"
                 :key="view.name_eng"
                 :data-text="view.name_th"
                 @click="changeView(view.name_eng)">
            {{ view.name_th }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row class="ma-0 pb-16 pb-sm-0 pt-3" style="height: calc(100% - 52px - 48px);">
      <v-col class="pl-0 pr-0 pr-sm-1 pt-0 pb-0 bridgeSvgContainer"
             cols="12"
             sm="8">
        <div class="bridgeContainer">
          <BridgeOverview
            v-if="state == 'overview' && destroy == false"
            :brFlag="brFlag"
            :bridgeDirection="bridgeDirection"
            :bridgePart="bridgePartSelected"
            :bridgeType="bridgeTypeSelected"
            :bridgeView="bridgeViewSelected"
            :bridgeSelected="bridgeSelected"
            :disableDirection="disableDirection"
            :endLocation="endLocation"
            :imageDirection="$vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical'"
            :startLocation="startLocation"
            @clicked="changeToBridgeComponent"
          />
          <BridgeComponent
            v-if="state == 'component'"
            :brFlag="brFlag"
            :bridgeComponentPartSelected="bridgeComponentPartSelected"
            :bridgeDirection="bridgeDirection"
            :bridgeSegmentType="bridgeSegmentType"
            :bridgeSelected="bridgeSelected"
            :bridgeView="bridgeViewSelected"
            :endLocation="endLocation"
            :seq="bridgePartIndex"
            :startLocation="startLocation"
            :isIndept="false"
            @clicked="changeToOverview"
          />
        </div>
      </v-col>
      <v-col class="pr-0 pl-0 pl-sm-1 pt-2 pt-sm-0 pb-0 bridgeDataContainer"
             cols="12"
             sm="4">
        <div class="bridgeContainer" style="overflow-y: auto;">
          <h3 class="mt-4 d-flex justify-center primary--text">รายละเอียด</h3>
          <v-row v-if="!isBridgeGeneralLoading" class="pt-3 mx-0">
            <v-col v-for="(item, index) in bridgeInfo" :key="index" class="px-3" cols="6">
              <strong>{{ item.name }}</strong>
              <br/>
              <span style="word-break: break-all;">{{ item.value }}</span>
            </v-col>
          </v-row>
          <v-row v-else align="center" class="ma-0" justify="center">
            <v-progress-circular
              color="grey lighten-2"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <a class="text-decoration-underline black--text pt-5 pb-4 d-flex justify-center"
             data-text="รายละเอียดเพิ่มเติม"
             @click="isInventoryDetailDialogShow = true">
            รายละเอียดเพิ่มเติม
          </a>
        </div>
      </v-col>
    </v-row>
    <InventoryDetailDialog
      v-model="isInventoryDetailDialogShow"
      @updateInventoryDetailDialog="updateInventoryDetailDialog"/>
  </div>
</template>

<script>
import InventoryDetailDialog from '../dialog/InventoryDetailDialog.vue'
import BridgeOverview from '@/components/bridge/bridgeOverview.vue'
import BridgeComponent from '@/components/bridge/bridgeComponent.vue'
import utils from '@/assets/js/utils'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    InventoryDetailDialog,
    BridgeOverview,
    BridgeComponent
  },
  data: () => ({
    getKm: utils.getKm,

    isInventoryDetailDialogShow: false,
    bridgeInfo: {},

    state: 'init',
    brFlag: 1,
    bridgePartIndex: 1,
    bridgePartCount: 4,
    bridgeFootCount: 3,
    bridgeSlabLayer: 4,
    bridgeBsCount: 2,
    bridgeTypeSelected: 'BG',
    bridgeSegmentType: 'BG',
    bridgeViewSelected: 'topView',
    bridgeViewSelectedForm: 'topView',
    bridgeComponentPartSelected: 'A1',
    bridgeDirection: 'left',
    startLocation: 'กรุงเทพ',
    endLocation: 'ชลบุรี',
    imageDirection: 'vertical',
    disableDirection: false,
    bridgePart: [['A1', 'S1', 'S2', 'S3', 'S4', 'A2']],
    bridgeView: [
      { value: 'sideView', label: 'หน้าตัด' },
      { value: 'topView', label: 'บนสะพาน' },
      { value: 'bottomView', label: 'ใต้สะพาน' }
    ],
    bridgePartSelected: null,
    bridgePartList: [],
    bridgeSelected: null,
    bridgeList: [],
    destroy: false,
    isBridgeGeneralLoading: false
  }),
  computed: {
    ...mapState('bridgeDetail', ['bridgeOverview', 'detail']),
    ...mapGetters('bridgeDetail', { canAdd_permission: 'canAdd_permission' }),
    ...mapGetters({ user_can_add_inspection: 'can_add_inspection' }),
    can_add_inspection () {
      return this.user_can_add_inspection && this.canAdd_permission
    }
  },
  mounted () {
    setTimeout(this.setBridgeOverview, 100)
  },
  watch: {
    bridgeViewSelectedForm (to, from) {
      console.log(to, from)
    }
  },
  methods: {
    async getBridgeGeneralDetail (partCode) {
      console.log(partCode)
      this.isBridgeGeneralLoading = true
      const payload = { bridgeId: this.$route.query.id, partCode }
      const bridgeInfo = await this.$store.dispatch('getBridgeGeneralDetail', payload)
      console.log(bridgeInfo)
      for (const key in bridgeInfo) {
        if (bridgeInfo[key] === null) {
          delete bridgeInfo[key]
        }
      }
      this.bridgeInfo = bridgeInfo
      this.setDetail() // set km pattern
      this.isBridgeGeneralLoading = false
    },
    updateInventoryDetailDialog (isShow) {
      this.isInventoryDetailDialogShow = isShow
    },
    setBridgeOverview () {
      let bridgePart = []
      this.bridgePartSelected = []

      this.startLocation = this.bridgeOverview.startLocation
      this.endLocation = this.bridgeOverview.endLocation
      if (this.bridgeOverview.direction === 'ซ้ายทาง') {
        this.bridgeDirection = 'right'
      } else if (this.bridgeOverview.direction === 'ขวาทาง') {
        this.bridgeDirection = 'left'
      }

      const sideType = ['Box Culvert', 'Box Underpass']
      this.bridgeList = []

      for (let j = 0; j < this.bridgeOverview.bridge_part.length; j++) {
        this.bridgeList.push({
          partcode: this.bridgeOverview.bridge_part[j][0].partcode,
          type: this.bridgeOverview.bridge_part[j][0].bridge_strurture_name,
          name: `สะพาน ${this.bridgeOverview.bridge_part[j][0].partcode}`
        })
      }
      console.log('set bridge')
      if (this.bridgeList.length > 0) {
        // this.null_bridge = false
        if (!this.bridgeSelected) {
          this.bridgeSelected = this.bridgeList[0]
          this.getBridgeGeneralDetail(this.bridgeSelected.partcode)
        }
        console.log(this.bridgeSelected)

        // this.bridgeSelected = { partcode: 'L1', type: 'Box Culvert' }

        this.bridgeTypeSelected = this.bridgeSelected.type

        let segmentHead = 'A'
        if (sideType.includes(this.bridgeSelected.type)) {
          segmentHead = 'WW'
        }
        if (this.bridgeOverview.has_a1) {
          bridgePart = [{ segment: segmentHead, seq: 1 }]
          // bridgePart = []
        } else {
          bridgePart = []
        }
        console.log(this.bridgeOverview.bridge_part)
        console.log(this.bridgeSelected)
        for (let i = 0; i < this.bridgeOverview.bridge_part.length; i++) {
          if (
            this.bridgeOverview.bridge_part[i][0].partcode ===
            this.bridgeSelected.partcode
          ) {
            console.log(this.bridgeOverview.bridge_part[i])
            for (
              let j = 1;
              j <= this.bridgeOverview.bridge_part[i].bridge_segment.length;
              j++
            ) {
              bridgePart.push({ segment: 'S', seq: j })
            }
          }
        }
        if (this.bridgeOverview.has_a2) {
          bridgePart.push({ segment: segmentHead, seq: 2 })
          // bridgePart = []
        }
        this.bridgePartSelected = bridgePart
        console.log(this.bridgePartSelected)

        if (sideType.includes(this.bridgeSelected.type)) {
          this.imageDirection = 'horizontal'
          this.bridgeViewSelected = 'sideView'
          this.bridgeViewSelectedForm = 2
          this.bridgeView = [{ name_eng: 'sideView', name_th: 'หน้าตัด' }]
        } else {
          this.imageDirection = this.$vuetify.breakpoint.xs
            ? 'vertical'
            : 'horizontal'
          this.bridgeView = [
            { name_eng: 'topView', name_th: 'บนสะพาน' },
            { name_eng: 'bottomView', name_th: 'ใต้สะพาน' },
            { name_eng: 'sideView', name_th: 'หน้าตัด' }
          ]
          // if (this.bridgeViewSelectedForm === 'sideView') {
          //   this.bridgeViewSelected = 'topView'
          //   this.bridgeViewSelectedForm = 'topView'
          // }
          // this.bridgeViewSelected = this.bridgeViewSelectedForm
        }
      } else {
        // this.null_bridge = true
        // this.null_text = 'ไม่มีข้อมูลสะพาน'
      }
      this.state = 'overview'
    },

    setDetail () {
      console.log(this.bridgeInfo)
      this.bridgeInfo[11].value = this.getKm(this.bridgeInfo[11].value)
      // this.bridgeInfo.road_code.value = parseInt(this.detail.info.road_code)
    },
    changeToBridgeComponent (value) {
      if (value.back === true) {
        this.state = 'component'
        this.bridgeComponentPartSelected = value
        this.bridgePartIndex = parseInt(
          this.bridgeComponentPartSelected.componentNo
        )
        if (
          this.bridgeComponentPartSelected.componentID === 'P' ||
          (this.bridgeComponentPartSelected.componentID === 'A' &&
            this.bridgeViewSelectedForm === 'bottomView')
        ) {
          this.bridgeViewSelected = 'crossSectionView'
          for (let j = 0; j < this.bridgeOverview.bridge_part.length; j++) {
            if (
              this.bridgeOverview.bridge_part[j][0].partcode ===
              this.bridgePartSelected
            ) {
              this.bridgeSegmentType =
                this.bridgeOverview.bridge_part[j].bridge_segment[
                  this.bridgePartIndex - 1
                ].segment_type_id
            }
          }
        }
        if (
          this.bridgeComponentPartSelected.componentID === 'S' &&
          this.bridgeComponentPartSelected.componentNo === 1
        ) {
          this.brFlag = 1
        } else {
          this.brFlag = 0
        }
      }
    },
    changeToOverview (value) {
      if (value.back === true) {
        this.state = 'overview'
        if (this.bridgeViewSelected === 'crossSectionView') {
          this.bridgeViewSelected = 'bottomView'
          this.bridgeViewSelectedForm = 'bottomView'
        }
      }
    },
    changeView (view) {
      this.bridgeViewSelectedForm = view
      this.bridgeViewSelected = view
      this.setBridgeOverview()
    },
    changeBridge (value) {
      this.destroy = true

      let bridgeSelected = {}
      this.imageDirection = this.$vuetify.breakpoint.xs
        ? 'vertical' : 'horizontal'

      for (let j = 0; j < this.bridgeList.length; j++) {
        if (this.bridgeList[j].partcode === value) {
          bridgeSelected = this.bridgeList[j]
        }
      }

      this.bridgeSelected = bridgeSelected
      this.getBridgeGeneralDetail(this.bridgeSelected.partcode)

      let bridgePart = []
      this.bridgePartSelected = []
      const sideType = ['Box Culvert', 'Box Underpass']

      let segmentHead = 'A'
      if (sideType.includes(this.bridgeSelected.type)) {
        segmentHead = 'WW'
      }
      if (this.bridgeOverview.has_a1) {
        bridgePart = [{ segment: segmentHead, seq: 1 }]
        // bridgePart = []
      } else {
        bridgePart = []
      }
      for (let i = 0; i < this.bridgeOverview.bridge_part.length; i++) {
        if (
          this.bridgeOverview.bridge_part[i][0].partcode ===
          this.bridgeSelected.partcode
        ) {
          for (
            let j = 1;
            j <= this.bridgeOverview.bridge_part[i].bridge_segment.length;
            j++
          ) {
            bridgePart.push({ segment: 'S', seq: j })
          }
        }
      }
      if (this.bridgeOverview.has_a2) {
        bridgePart.push({ segment: segmentHead, seq: 2 })
        // bridgePart = []
      }
      this.bridgePartSelected = bridgePart
      console.log(this.bridgePartSelected)

      setTimeout(() => {
        this.changeStateToOverview()
        this.changeToOverview({ back: true })
      }, 100)
    },
    changeStateToOverview () {
      this.destroy = false
    }
  }
}
</script>

<style>
.bridgeContainer {
  border: thin solid #C22D47;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .bridgeSvgContainer {
    height: 450px;
  }

  .bridgeDataContainer {
    height: fit-content;
  }
}

@media screen and (min-width: 600px) {
  .bridgeSvgContainer, .bridgeDataContainer {
    height: 100%;
  }
}
</style>
